<template>
  <div class="forgetpassword">
    <div class="top">
      <div class="con">
        <p class="title" style="
    cursor: pointer;
    position: absolute;
    top: 59px;
"><img src="../images/zzsrloginlogo.png" alt=""  @click="$router.push({ path: '/' })"/></p>
        <!-- <p class="huanying" @click="$router.push({ path: '/' })">
          欢迎来到尉氏县公共数字文化平台，前往首页
        </p> -->
      </div>
    </div>
    <div class="middle">
      <div class="con">
        <p class="title">密码重置</p>
        <el-form :model="ruleForm" style="width: 80%; margin: 30px auto 20px">
          <el-form-item prop="phone">
            <div class="yyq">
              <div class="phonenu"><span>用户名:</span></div>
              <el-input
                type="phone"
                v-model="ruleForm.loginName"
                autocomplete="off"
                placeholder="请输入用户名"
              ></el-input>
            </div>
          </el-form-item>
           <el-form-item prop="phone">
            <div class="yyq">
              <div class="phonenu"><span>手机号:</span></div>
              <el-input
                type="phone"
                v-model="ruleForm.phone"
                autocomplete="off"
                placeholder="请输入11位手机号码"
              ></el-input>
            </div>
          </el-form-item>
          <!-- <el-form-item prop="imgcode" style="position: relative">
            <el-input
              v-model="ruleForm.imgcode"
              autocomplete="off"
              placeholder="请输入图形验证码"
            ></el-input>
            <span v-if="isshow == 1" class="getcode" @click="dianji"
              >点击获取验证码</span
            >
            <span v-if="isshow == 2" class="getcode" @click="getimgcode"
              ><img :src="src" alt=""
            /></span>
          </el-form-item> -->
          <el-form-item prop="numcode" style="position: relative">
            <div class="yyq">
              <span class="phonenu">手机验证码:</span>
              <el-input
                v-model="ruleForm.numcode"
                autocomplete="off"
                placeholder="请输入手机验证码"
              ></el-input>
              <button
                class="getcode"
                type="button"
                @click="getnumcode"
                :disabled="disabled"
              >
                {{ this.btnTitle }}
              </button>
            </div>
          </el-form-item>
          <el-form-item prop="password">
            <div class="yyq">
              <span class="phonenu">新密码:</span>
              <el-input
                type="password"
                v-model="ruleForm.password"
                autocomplete="off"
                placeholder="密码长度8-18位必须包含数字和字母"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item prop="passwordagain">
            <div class="yyq">
              <span class="phonenu">新密码:</span>
              <el-input
                type="password"
                v-model="ruleForm.passwordagain"
                autocomplete="off"
                placeholder="确认新密码"
              ></el-input>
            </div>
          </el-form-item>

          <!-- <el-checkbox v-model="ruleForm.checked" style="margin: 20px 0px 0px;"
            >我已阅读并接受相关条款</el-checkbox
          > -->
          <p class="next" @click="xiugai">确定</p>
          <p class="link">
            <!-- <span class="left" @click="$router.push({ path: '/register' })"
              >注册</span
            > -->
            <span class="left">已想起密码?</span>
            <span class="right" @click="$router.push({ path: '/AccountLogin' })"
              >立即登录</span
            >
          </p>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import API from "../api/api_login";
import Env from "../api/env";
export default {
  name: "forgetpassword",
  data() {
    return {
      active: 0,
      ruleForm: {
        phone: "",
        imgcode: "",
        numcode: "",
        password: "",
        passwordagain: "",
        checked: false,
        loginName:""
      },
      isshow: 1,
      btnTitle: "发送验证码",
      src: "",
      disabled: false,
    };
  },
  props: {},
  methods: {
    dianji() {
      this.isshow = 2;
      this.getimgcode();
    },
    //图片验证吗
    getimgcode() {
      var num = Math.ceil(Math.random() * 10); //生成一个随机数（防止缓存）
      this.src = Env.baseURL + "/api/user/getCaptcha?" + num;
    },
    getnumcode() {
      //倒计时
      if (!/^1[3456789]\d{9}$/.test(this.ruleForm.phone)) {
        this.$message({
          showClose: true,
          message: "请输入正确的手机号",
          type: "error",
        });
      } else {
        this.validateBtn();
        this.getRegSmsCode();
      }
    },
    //手机短信
    getRegSmsCode() {
      // RegSmsCode
      API.RegSmsCode({
        telephone: this.ruleForm.phone,
      }).then((res) => {
        if (res.code == 0) {
          this.$message({
            showClose: true,
            message: "短信已发送，请注意查收",
            type: "success",
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    validateBtn() {
      let time = 60;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.disabled = false;
          this.btnTitle = "获取验证码";
        } else {
          this.btnTitle = time + "秒后重试";
          this.disabled = true;
          time--;
        }
      }, 1000);
    },
    xiugai() {
      if (
        this.ruleForm.phone == "" ||
        // this.ruleForm.imgcode == "" ||
        this.ruleForm.numcode == "" ||
        this.ruleForm.password == "" ||
        this.ruleForm.passwordagain == ""|| this.ruleForm.loginName==""
      ) {
        this.$message.error("请将内容填写完整");
      } else if (!/^1[3456789]\d{9}$/.test(this.ruleForm.phone)) {
        this.$message({
          showClose: true,
          message: "请输入正确的手机号",
          type: "error",
        });
      } else if (this.ruleForm.password != this.ruleForm.passwordagain) {
        this.$message({
          showClose: true,
          message: "两次输入密码不一致",
          type: "error",
        });
      } else {
        API.forgetpass({
          loginName:this.ruleForm.loginName.replace(/\s/g, ""),
          telephone: this.ruleForm.phone.replace(/\s/g, ""),
          // code: this.ruleForm.imgcode.replace(/\s/g, ""),
          smsCode: this.ruleForm.numcode.replace(/\s/g, ""),
          password: this.ruleForm.password.replace(/\s/g, ""),
          confirmPWD: this.ruleForm.passwordagain.replace(/\s/g, ""),
        }).then((res) => {
          if (res.code == 0) {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "success",
            });
            setTimeout(() => {
              this.$router.push({ path: "/accountlogin" });
            }, 1000);
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
  },
};
</script>

<style scoped>
::v-deep .el-input {
  position: relative;
  font-size: 14px;
  display: inline-block;
  /* width: 100%; */
  width: 307px;
  margin-left: 13px;
}
.yyq {
  position: relative;
  display: flex;
  width: 425px;
}
.phonenu {
  /* 不允许换行 */
  white-space: nowrap;
  width: 30%;
  text-align: right;
  font-size: 14px;
  color: #333333;
}
.forgetpassword {
  width: 100%;
  height: 100vh;
  background-image: url(../images/zzsrlogin.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.forgetpassword .top {
  width: 100%;
  height: 40px;
  /* background-color: rgba(0, 0, 0, 0.4); */
  font-family: Noto Serif SC;
  color: #fff;
 
}
.forgetpassword .top .con {
  width: 70%;
  margin: 0 auto;
  display: flex;
   position: relative;
  
}
.forgetpassword .top .title {
  margin: 0;
  display: inline-block;
  width: 50%;
  height: 100%;
  line-height: 40px;
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  margin-top: 20px;
}
.forgetpassword .top .huanying {
  margin: 0;
  display: inline-block;
  width: 50%;
  height: 100%;
  line-height: 40px;
  text-align: right;
  font-size: 12px;
}
.forgetpassword .middle {
  margin: 0 auto;
  width: 50%;
  height: auto;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-top: calc(33vh - 245px);
}
.forgetpassword .middle .con {
  /* width: calc(100% -20px); */
  /* height: auto;
  padding: 100px 75px; */
  width: 43%;
  margin-left: 57%;
  margin-top: 28%;
  height: auto;
}
.forgetpassword .middle .con .title {
  font-family: SourceHanSansCN-Medium;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 30px;
  letter-spacing: 0px;
  color: #ae703e;
  /* padding-left: 52px; */
}
::v-deep .el-input__inner {
  width: 315px;
  height: 40px;
}
.forgetpassword .middle .con .title .right {
  letter-spacing: 0px;
  font-size: 14px;
  /* float: right; */
  line-height: 40px;
  color: #666;
}
.forgetpassword .el-step__icon.is-text {
  background-color: #e94f35;
}
.forgetpassword .middle .con .getcode {
  display: inline-block;
  width: 92px;
  height: 96%;
  color: white;
  font-size: 10px;
  position: absolute;
  right: -7px;
  top: 1px;
  text-align: center;
  border: none;
  background-color: #e26f12;
  border-radius: 0px 5px 5px 0px;
  outline: none;
}
.el-input__inner {
  width: 100% !important;
}
.forgetpassword .middle .con .getcode::before {
  content: "";
  display: inline-block;
  width: 1px;
  height: 21px;
  position: absolute;
  top: 10px;
  left: 0px;
}
.forgetpassword .middle .con .getcode img {
  width: 100%;
  height: 100%;
}
.forgetpassword .middle .con .link {
  width: 100%;
  text-align: end;
  margin-left: 31%;
}
.forgetpassword .middle .con .link .left {
  font-size: 14px !important;
  width: 50%;
  height: auto;
  font-size: 9px;
  color: #999999;
  text-align: left;
}
 .getcode ::before {
  
    background-color: none !important;}
    
.forgetpassword .middle .con .link .right {
  font-size: 14px !important;
  width: 50%;
  height: auto;
  font-size: 12px;
  color: #2882fe;
  text-align: right;
}
.forgetpassword .middle .con .next {
width: 100%;
    height: 47px;
    /* margin-left: -3%; */
    margin: 0 auto;
    margin-left: 32%;
    background-color: #ae703e;
    font-size: 14px !important;
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    line-height: 49px;
    cursor: pointer;

}
</style>
